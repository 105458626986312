export const getByContrato = (axios, contrato_codigo) => axios.get(`segurado/${contrato_codigo}`)

export const getFiltro = (axios) => axios.get(`segurado/consulta/filtro`)

export const addSegurado = (axios, payload) => axios.post(`segurado`, payload)

export const editSegurado = (axios, payload) => axios.put(`segurado`, payload)

export const deleteSegurado = (axios, segurado_codigo) => axios.delete(`segurado/${segurado_codigo}`)

export const importByCliente = (axios, payload) => axios.post(`segurado/import`, payload)

export const printCertificado = (axios, payload) => axios.post('segurado/report/certificado', payload, {
    responseType: 'blob'
})

export const printSorteio = (axios, payload) => axios.post('segurado/report/sorteio', payload, {
    responseType: 'blob'
})